import {
    AimOutlined,
    AuditOutlined,
    DollarOutlined,
    DeploymentUnitOutlined,
    QuestionCircleOutlined,
    SafetyOutlined,
} from '@ant-design/icons';
import Button from '../Button/Button';
import './Whyus.scss';

const Whyus: React.FC = () => {
    return (
        <section id="whyus" className="margin-top-50">
            <div className="container">
                <h2 className="pf-h2">Why Us</h2>
                <p className="pf-subtitle">
                    Here are some of many reasons why you should choose our services
                </p>
                <ul className="why-us list--none">
                    <li className="why-us__card">
                        <AuditOutlined className="why-us__icon" />
                        <div>
                            <h3>We are professionals</h3>
                            <p>
                                with over 20 years of experience with financial advising and
                                planning
                            </p>
                        </div>
                    </li>
                    <li className="why-us__card">
                        <AimOutlined className="why-us__icon" />
                        <div>
                            <h3>Single point of contact</h3>
                            <p>
                                for all the financial products in Czech Republic. You literally
                                never have to go anywhere else
                            </p>
                        </div>
                    </li>
                    <li className="why-us__card">
                        <SafetyOutlined className="why-us__icon" />
                        <div>
                            <h3>We are independent</h3>
                            <p>
                                of any financial institution, so our services can be objective and
                                personalized to client's individual needs
                            </p>
                        </div>
                    </li>
                    <li className="why-us__card">
                        <DollarOutlined className="why-us__icon" />
                        <div>
                            <h3>We save our clients time and money</h3>
                            <p>Our know-how helps to achieve great and fast results.</p>
                        </div>
                    </li>
                    <li className="why-us__card">
                        <DeploymentUnitOutlined className="why-us__icon" />
                        <div>
                            <h3>Our network</h3>
                            <p>consists of large amount of partners you will have access to</p>
                        </div>
                    </li>
                    <li className="why-us__card">
                        <QuestionCircleOutlined className="why-us__icon" />
                        <div>
                            <h3>If you are still not sure</h3>
                            <p>just come meet us for coffee and chat</p>
                            <Button href="#contact" text="Contact us" />
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    );
};
export default Whyus;
