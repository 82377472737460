import React from 'react';
import './Hero.scss';
import Button from '../Button/Button';
const Hero: React.FC = () => {
    return (
        <section className="hero">
            <div className="container margin-auto">
                <div className="heroHeading">
                    <span className="business">
                        Everything from simple car insurance to investing on foreign markets.{' '}
                    </span>
                    <h1>Personalized wealth management</h1>
                    <Button href="#whatwedo" text="Learn More" />
                </div>
                <div className="cardContainer">
                    <div className="card">
                        <h4>Insurance</h4>
                        <p>
                            Are you searching for an insurance contract, savings account or loan and
                            you don't know what to choose from the vast, ever-changing offer on the
                            market?
                        </p>
                    </div>
                    <div className="card">
                        <h4>Savings</h4>
                        <p>
                            Do you feel like you are not able to save any money from one month to
                            another and would finally like to take control of your finances?{' '}
                        </p>
                    </div>
                    <div className="card">
                        <h4>Investment</h4>
                        <p>Are you searching for interesting investment opportunities?</p>
                    </div>
                    <div className="card">
                        <h4>Business</h4>
                        <p>
                            Do you want to start a business and need a nudge in the right direction?
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Hero;
