import './App.css';

import Carrers from './Careers/Carrers';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Hero from './Hero/Hero';
import Mystory from './Mystory/Mystory';
import Whatwedo from './Whatwedo/Whatwedo';
import GoTop from './GoTop/GoTop';
import Whyus from './Whyus/Whyus';

function App() {
    return (
        <>
            <Header />
            <Hero />
            <Whatwedo />
            <Whyus />
            <Mystory />
            <Carrers />
            <Contact />
            <Footer />
            <GoTop />
        </>
    );
}

export default App;
