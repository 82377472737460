import React from 'react';
import { ArrowRightOutlined } from '@ant-design/icons';
import './Button.scss';

interface ButtonProps {
    href: string;
    text: string;
}

const Button: React.FC<ButtonProps> = ({ href, text }) => {
    return (
        <a className="btn" href={href}>
            <ArrowRightOutlined className="btn__arrow" />
            <span className="btn__label">{text}</span>
        </a>
    );
};
export default Button;
