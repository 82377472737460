import React from 'react';
import ReactDOM from 'react-dom';

import PersonalFinances from './personalfinances/App';

ReactDOM.render(
  <React.StrictMode>
    <PersonalFinances />
  </React.StrictMode>,
  document.getElementById('root')
);


