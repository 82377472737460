import React from 'react';
import Teamphoto from './team.jpg';
const Carrers: React.FC = () => {
    return (
        <section id="career" className="my-story margin-top-50">
            <div className="container contact">
                <div className="contact-left">
                    <img loading="lazy" src={Teamphoto} />
                </div>
                <div className="contact-right">
                    <h2 className="pf-h2">Careers</h2>
                    <span className="lead">
                        We are searching to enlarge our professional team of financial advisors.
                    </span>

                    <p>
                        Do you feel like you have enough of working for somebody else's company?
                        <br />
                        Would you like to invest time in your own business? Come do business with
                        us.
                    </p>
                    <p>
                        We can show you how to become an entrepreneur. We offer full training
                        program, couching and mentoring along the way.{' '}
                    </p>
                </div>
            </div>
        </section>
    );
};
export default Carrers;
