import React from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { useInView } from 'react-intersection-observer';

import LogoDark from './logo-dark.png';
import LogoLight from './logo-light.png';
import './Header.scss';

const Header: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const { ref, inView } = useInView({
        initialInView: true,
        rootMargin: '15%', // padding-top of .heroHeading
    });

    return (
        <>
            <div id="header" className="header__scroll-trigger" ref={ref} />
            <header className={`header ${inView ? '' : 'header--scrolled'}`}>
                <nav className="container">
                    <div className="header__nav">
                        <a className="header__logo" href="/">
                            <img
                                alt="PersonalFinances EU Logo"
                                className="header__logo-light"
                                src={LogoLight}
                                width="240px"
                            />
                            <img
                                alt="PersonalFinances EU Logo"
                                className="header__logo-dark"
                                src={LogoDark}
                                width="240px"
                            />
                        </a>
                        <button className="header__menu-trigger" onClick={() => setIsOpen((isOpen) => !isOpen)}>
                            {!!isOpen && <CloseOutlined className="header__menu-icon-close" />}
                            {!isOpen && <MenuOutlined className="header__menu-icon-open" />}
                        </button>
                        <ul className={`header__menu ${isOpen ? 'header__menu--is-open' : ''} list--none`}>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#whatwedo">
                                    What we do
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#whyus">
                                    Why us
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#mystory">
                                    My story
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#success">
                                    Success stories
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#career">
                                    Career
                                </a>
                            </li>
                            <li>
                                <a onClick={() => setIsOpen(false)} href="#contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </>
    );
};
export default Header;
