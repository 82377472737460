import React from 'react';
const Mystory: React.FC = () => {
    return (
        <section id="mystory" className="my-story margin-top-50">
            <div className="container">
                <h2 className="pf-h2">My story</h2>
                <p>
                    My name is Daniel Hlavac and I live in Brno for all of my adult life. When I was
                    20, I started working on my career for an American corporation. Most of my
                    colleagues were expats - back then simply called foreigners. They struggled with
                    Czech language every time they needed to deal with an official somewhere, thus
                    calling upon us, colleagues who spoke both Czech and English, for assistance.
                    Residence permits, tax statements, vehicle purchases, hospitals, banks, utility
                    companies, you name it. At some point everybody met a person, who only spoke
                    Czech.{' '}
                </p>
                <p>
                    It struck me how little amount of information about financial products is
                    available in English language. About 3 years ago I connected my experience of
                    working with foreigners in Brno with know-how of{' '}
                    <a href="http://www.leopoldnovacek.cz/">Leopold Novacek's</a> company, who
                    specializes for more than 20 years in financial counselling and planning. We
                    partnered up to bring financial service, that are so common in local market, to
                    people who don't speak Czech. In these stressful times, I want to make lives of
                    people around me easier. Finances is where we start.
                </p>
            </div>
        </section>
    );
};
export default Mystory;
