import React from 'react';
import Contactphoto from './contact.jpg';
const Contact: React.FC = () => {
    return (
        <section id="contact" className="my-story margin-top-50">
            <div className="container contact">
                <div className="contact-left">
                    <h2 className="pf-h2">Contact us</h2>
                    <span className="lead">Tell us about your needs.</span>
                    <p>
                        We want to evolve and bring you the most suitable services that will make
                        your life easier. Do you need help with taxes, accounting or real estate
                        services? Are you in debt? Contact us for consultations and more info.
                    </p>
                    <p>
                        <span className="icon">☎️</span> +420 770 697 588
                    </p>
                    <p>
                        <span className="icon">✉️</span> info@personalfinances.eu
                    </p>
                </div>
                <div className="contact-right">
                    <img loading="lazy" src={Contactphoto} />
                </div>
            </div>
        </section>
    );
};
export default Contact;
