import React from 'react';

import Problempic from './problem.jpg';
import Solutionpic from './solution.jpg';
import './Whatwedo.scss';

const Whatwedo: React.FC = () => {
    return (
        <section id="whatwedo">
            <div className="container margin-top-50">
                <h2 className="pf-h2">What we do</h2>
                <p className="pf-subtitle">
                    There are more than 100 major financial institutions like banks, saving banks,
                    insurance companies out there in Czech Republic, each offering different set of
                    these products in their portfolio
                </p>

                <ul className="features list--none">
                    <li>Bank accounts</li>
                    <li>Car insurance</li>
                    <li>Property insurance</li>
                    <li>Household insurance</li>
                    <li>Life insurance</li>
                    <li>Liability insurance</li>
                    <li>Pension savings</li>
                    <li>Household build savings</li>
                    <li>Credit card</li>
                    <li>Mortgage</li>
                    <li>Open mutual funds investments</li>
                    <li>Personal loan</li>
                </ul>
                <div className="what-we-do__article">
                    <img loading="lazy" alt="" className="problem-pic" src={Problempic} />
                    <p>
                        There are literally hundreds of options. How would you choose the best one
                        from the lot? Do you go to the bank that has the closest branch to your
                        location? Well I think at best, your research would take a long time. Most
                        of the companies don't have any documentation in English language though.
                    </p>
                </div>
                <div className="what-we-do__article">
                    <p>
                        We offer different approach. We have tools available, that allow us access
                        to all these products from our desks. After analysis of your financial
                        situation, we can always cherry pick the most suitable product and financial
                        institution for any specific situation. We prepare all the paperwork needed
                        and act as your liaison in communication with the financial institution.
                        During our meetings we will introduce to you a philosophy of financial
                        planning, philosophy that shows how planning ahead makes a lot of
                        difference, especially when we talk about money.{' '}
                    </p>
                    <img loading="lazy" alt="" src={Solutionpic} />
                </div>
            </div>
        </section>
    );
};
export default Whatwedo;
