import React from 'react';
import arrow from './up-arrow.svg';
import './GoTop.scss';
const GoTop: React.FC = () => {
    return (
        <a href="#header">
            <img className="arrow-btn" src={arrow} />
        </a>
    );
};
export default GoTop;
